/**
|--------------------------------------------------
| APP ROUTER
|--------------------------------------------------
*/
import 'styles/_variables.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-datepicker/src/stylesheets/datepicker.scss"

// import 'react-circular-progressbar/dist/styles.css'
// import 'react-perfect-scrollbar/dist/css/styles.css'
// import 'react-big-calendar/lib/css/react-big-calendar.css'
// import 'react-image-lightbox/style.css'
// import 'video.js/dist/video-js.css'
import 'styles/app.scss'
import 'types/style.scss'
import 'types/modal.scss'
import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { IntlProvider } from "react-intl"
// import { is_authenticated, set_local_storage, get_local_storage, is_empty } from "utils"
// import { vi, en } from "translations"

// import firebase from 'firebase/app'
// import 'firebase/messaging'
// import platform from 'platform'
// import ErrorBoundary from './ErrorBoundary'
// const NotFound = lazy(() => import('pages/404'))

import { FallbackSpinner } from 'components/commons'
const DashboardRouter = lazy(() => import('./DashboardRouter1'))
const Login = lazy(() => import('pages/login'))

const supportsHistory = 'pushState' in window.history

function AppRouter() {
	let state = {
		"default_locale": "vi",
		"messages": "...",
		"change_locale": {}
	} 

	return (
	  <BrowserRouter>
	  	<Suspense fallback={<FallbackSpinner />} >
		  <IntlProvider locale={state.default_locale} messages={state.messages}>
		<Routes>
		  <Route path="/*" element={<DashboardRouter />} />
		  {/* <Route path="users/*" element={<Users />} /> */}
		  <Route path="/login" element={<Login />} />
		</Routes>
		</IntlProvider>
		</Suspense>
	  </BrowserRouter>
	);
  }

export default AppRouter