// import Cookies from 'js-cookie'
// import { CONFIG } from 'configs'
import { get_local_storage, set_local_storage } from "./storage"

// const { TOKEN_KEY, TOKEN_COOKIE_EXPIRES } = CONFIG

// const get_token_cookies = () => Cookies.get(TOKEN_KEY)

const get_token_storage = () => get_local_storage("token")

// const set_token_cookies = (token, remember = false) => {
// 	if (remember) {
// 		return Cookies.set(TOKEN_KEY, token, { "expires": TOKEN_COOKIE_EXPIRES })
// 	}
// 	return Cookies.set(TOKEN_KEY, token)
// }

const set_authenticated_storage = ({ access_token, username, id, roles, department, garage }, remember_me = true) => {
	// const expires = new Date().getTime()
	set_local_storage("token", access_token)
	set_local_storage("username", username)
	set_local_storage("id", id)
	set_local_storage("roles", roles)
	set_local_storage("department", department)
	set_local_storage("garage", garage)
}

// const remove_token_cookies = () => Cookies.remove(TOKEN_KEY)

const remove_token_storage = () => localStorage.clear()

const is_authenticated = () => {
	// return true
	try {
		const token = get_token_storage('token')
		if (token) {
			return true
		}
		return false

		// const expires_at = get_local_storage('expires_at')
		// const now = new Date().getTime()
		// if (!is_empty(token) && !is_empty(expires_at) && (now < expires_at)) {
		// 	return true
		// } else {
		// 	// remove_token_storage()
		// 	return false
		// }
	} catch (error) {
		// remove_token_storage()
		return false
	}
}

const has_permission = (role = []) => {
	return true

	// if (role && role instanceof Array && role.length > 0) {
	// 	const roles = get_local_storage("user").roles
	// 	const permission_roles = role
	// 	const has_permission = roles.some(r => {
	// 		return permission_roles.includes(r)
	// 	})

	// 	if (!has_permission) {
	// 		return false
	// 	}

	// 	return true
	// } else {
	// 	console.error(`need roles! Like ['admin','editor']`)
	// 	return false
	// }
}

export { get_token_storage, set_authenticated_storage, remove_token_storage, is_authenticated, has_permission }