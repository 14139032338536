/**
|--------------------------------------------------
| MODAL CONFIRM ACTION 
| <ModalConfirm
| 	messages="Are you sure?"
| 	open={open}
| 	confirms={() => {
| 		set_open(false)
| 		console.log("on yes click")
| 	}}
| 	cancels={() => {
| 		set_open(false)
| 		console.log("on cancel click")
| 	}}
| />
| @vunv

|--------------------------------------------------
*/

import React, { useState, useEffect } from 'react'
import { FormattedMessage } from "react-intl"
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalConfirm = (props) => {
	let [open, toggle] = useState(false)
	console.log('==========props=========',props)
	useEffect(() => {
		toggle(props.open)
		return () => {
			toggle(false)
		}
	}, [open, props])

	const _on_yes = () => {
		props.confirms()
		toggle(false)
	}

	const _on_cancel = () => {
		if (props.cancels) {
			props.cancels()
		}
		toggle(false)
	}

	return (<React.Fragment>
		<Modal
			isOpen={open}
			// toggle={() => _on_cancel()} 
			className={`modal-dialog-centered modal-dialog-scrollable modal-confirm`}
		>
			<ModalHeader>{props.title}</ModalHeader>
			<ModalBody className="modal-confirm-body">
				<p className="text-center" >{props.messages}</p>
			</ModalBody>
			<ModalFooter className="justify-content-center">
				<Button color="danger" onClick={() => _on_yes()}>Đồng ý</Button>{' '}
				<Button outline color="secondary" onClick={() => _on_cancel()}>Hủy</Button>
			</ModalFooter>
		</Modal>
	</React.Fragment>)
}

ModalConfirm.propTypes = {
	messages: PropTypes.any,
	open: PropTypes.bool,
	confirms: PropTypes.func,
	cancels: PropTypes.func
}

ModalConfirm.defaultProps = {
	messages: "Bạn chắc chắn muốn xoá nội dung này?",
	open: false,
	confirms: () => console.log("confirms"),
	cancels: () => console.log("cancels")
}

export default React.memo(ModalConfirm)